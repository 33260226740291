import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "./redux/userActions"; // Assuming this action sets the user in Redux
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import AdPage from "./pages/AdPage";
import EditProfile from "./components/EditProfile";
import Home from "./pages/Home";

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    const storedUser = localStorage.getItem("rideAd247-user");
    if (storedUser) {
      dispatch(login(JSON.parse(storedUser)));
    }
  }, [dispatch]);
  return (
    <Router>
      <div className="App">
        <Routes>
          {user ? (
            <>
              <Route path="/" element={<Dashboard />} />
              <Route path="/edit-profile" element={<EditProfile />} />
            </>
          ) : (
            <>
              <Route path="/" element={<Home />} />
            </>
          )}
          <Route path="/play-ad" element={<AdPage />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

