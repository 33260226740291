import React from "react";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <div className="h-screen flex flex-col">
      <header className="absolute top-4 left-4 z-10">
        <img
          src={require("../images/rideadLogo.png")}
          alt="RideAd Logo"
          style={{ height: "50px", width: "70px", borderRadius: "8px" }}
        />
      </header>

      <section className="flex flex-col items-center justify-center h-full bg-[#F46D21] text-white text-center px-4">
        <div className="flex flex-col z-20">
          <h1 className="text-4xl font-bold mb-4" style={{ color: "#fff" }}>
            Welcome to RideAd
          </h1>
          <p className="text-lg mb-6">
            Watch advertisements during your ride and get paid instantly! Join
            the innovation today.
          </p>

          <div className="mt-16 mb-11">
            <Link
              to="/login"
              className="px-11 m-9 font-bold text-lg py-[20px] bg-white text-[#F46D21] rounded-lg shadow-md mb-6"
              style={{ width: "400px", textAlign: "center" }}
            >
              Login As A Driver
            </Link>
          </div>
          {/* Contact Section */}
          <div className="text-sm">
            <h2 className="font-semibold text-xl mb-4">Contact</h2>
            <p>
              <a href="mailto:support@ridead247.com">support@ridead247.com</a>
            </p>
          {/*  <p>
              <a href="tel:+233555555555">+233 555 555 555</a>
            </p> */}
          </div>
        </div>
      </section>
    </div>
  );
}

