import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../redux/userActions";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import { FaArrowLeft } from "react-icons/fa"; // Import an arrow icon

const EditProfile = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const navigate = useNavigate(); // Initialize useNavigate for routing
  const [firstName, setFirstName] = useState(user.driver.first_name || "");
  const [lastName, setLastName] = useState(user.driver.last_name || "");
  const [phoneNumber, setPhoneNumber] = useState(user.driver.phone || "");
  const UpdatData = { firstName, lastName, phoneNumber };
  const handleSave = () => {
    if (firstName && lastName && phoneNumber) {
      dispatch(updateProfile(user.driver.id, UpdatData));
    } else {
      toast.error("Please fill in all required fields!");
    }
  };

  const goBackToDashboard = () => {
    navigate("/");
  };

  return (
    <div className="h-full md:py-20 p-10 flex flex-col items-center justify-center bg-gray-100">
      <div className="p-8 bg-white shadow-lg rounded-lg w-full max-w-md">
        <div className="flex items-center mb-6">
          <button onClick={goBackToDashboard} className="mr-4">
            <FaArrowLeft className="text-blue-600 hover:text-blue-800" />{" "}
          </button>
          <h1 className="text-2xl font-bold">Edit Profile</h1>
        </div>
        <div className="mb-4">
          <label className="block mb-2">First Name</label>
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="p-4 bg-gray-100 text-l mt-2 rounded-xl w-full max-w-[400px] outline-none"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Last Name</label>
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="p-4 bg-gray-100 text-l mt-2 rounded-xl w-full max-w-[400px] outline-none"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Phone Number</label>
          <input
            type="text"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="p-4 bg-gray-100 text-l mt-2 rounded-xl w-full max-w-[400px] outline-none"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2 ">Location</label>
          <input
            type="text"
            value={user.driver.target_location}
            disabled={true}
            className="p-4 text-gray-400 bg-gray-100 text-l mt-2 rounded-xl w-full max-w-[400px] outline-none"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 ">Region</label>
          <input
            type="text"
            value={user.driver.target_region}
            disabled={true}
            className="p-4 text-gray-400 bg-gray-100 text-l mt-2 rounded-xl w-full max-w-[400px] outline-none"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 ">Country</label>
          <input
            type="text"
            value={user.driver.target_country}
            disabled={true}
            className="p-4 text-gray-400 bg-gray-100 text-l mt-2 rounded-xl w-full max-w-[400px] outline-none"
          />
        </div>
        <button
          onClick={handleSave}
          className="p-4 font-bold text-l rounded-xl w-full bg-blue-600 text-white mt-6 max-w-[400px]"
        >
          Save Changes
        </button>
      </div>
    </div>
  );
};

export default EditProfile;

