import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactPlayer from "react-player";
import { FaPlay, FaStepForward } from "react-icons/fa";
import ReactLoading from "react-loading";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../redux/Baseurl";

const generateUniqueId = () => {
  return `rider_${new Date().getTime()}_${Math.random()
    .toString(36)
    .substr(2, 9)}`;
};

const AdPage = () => {
  const location = useLocation();
  const [paymentNumber, setPaymentNumber] = useState("");
  const [isAdWatched, setIsAdWatched] = useState(false);
  const [isPayed, setIsPayed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [playNow, setPlayNow] = useState(false);
  const [adsArray, setAdsArray] = useState([]);
  const [noAdsAvailable, setNoAdsAvailable] = useState(false);
  const [isPaying, setIsPaying] = useState(false);
  const handleRefresh = () => {
    window.location.reload();
  };
  const getQueryParams = (search) => {
    return new URLSearchParams(search);
  };

  useEffect(() => {
    let riderId = localStorage.getItem("rideAd247_rider_identity");
    if (!riderId) {
      riderId = generateUniqueId();
      localStorage.setItem("rideAd247_rider_identity", riderId);
    }
    const queryParams = new URLSearchParams(location.search);
    const driverId = queryParams.get("driverId");
    const filterAds = async () => {
      setIsPaying(false);
      try {
        const response = await axios.post(`${BASE_URL}/ads/filter`, {
          rider_id: riderId,
          driver_id: driverId
        });
        if (!response.data.ad) {
          setNoAdsAvailable(true);
        } else {
          setAdsArray(response.data.ad);
        }
        setIsLoading(false);
      } catch (error) {
        setNoAdsAvailable(true);
        setIsLoading(false);
      }
    };

    filterAds();
  }, [location]);

  const handleAdEnd = () => {
    setIsAdWatched(true);
    setPlayNow(false);
  };

  const handleClaim = () => {
    let riderId = localStorage.getItem("rideAd247_rider_identity");

    const queryParams = getQueryParams(window.location.search);
    const driverId = queryParams.get("driverId");

    if (!paymentNumber) {
      toast.error("Please enter a payment number.");
      return;
    }

    const validPrefixes = [
      "24",
      "25",
      "53",
      "54",
      "55",
      "59",
      "20",
      "50",
      "27",
      "57",
      "26",
      "56"
    ];

    const phoneRegex = new RegExp(`^0(${validPrefixes.join("|")})\\d{7}$`);

    if (!phoneRegex.test(paymentNumber)) {
      toast.error(
        "Please enter a valid 10-digit phone number starting with 0."
      );
      return;
    }

    const prefix = paymentNumber.slice(1, 3);
    if (!validPrefixes.includes(prefix)) {
      toast.error("Invalid phone number prefix.");
      return;
    }

    if (
      adsArray.payed_numbers &&
      adsArray.payed_numbers.includes(paymentNumber)
    ) {
      toast.info("You have already been paid for watching this ad.");
      return;
    }

    setIsPaying(true);
    fetch(`${BASE_URL}/disburse-funds`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        driverId,
        riderPhone: `233${paymentNumber.slice(1)}`,
        amount_for_each: adsArray.amount_for_each,
        rider_id: riderId,
        ad_id: adsArray.id
      })
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 422) {
            throw new Error(response.message);
          } else {
            throw new Error("Failed to process payment.");
          }
        }
        return response.json();
      })
      .then((data) => {
        setIsPaying(false);
        setIsAdWatched(false);
        toast.success(`You've been credited! ${adsArray.amount_for_each} GHC`);
        setIsPayed(true);
      })
      .catch((error) => {
        setIsPaying(false);
        toast.error("something went wrong");
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "paymentNumber") {
      setPaymentNumber(value);
    }
  };

  return (
    <div className="flex flex-col items-center bg-black h-screen w-screen justify-center overflow-hidden">
      {isLoading ? (
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-[#F46D21]">
          <ReactLoading
            type={"bars"}
            color={"white"}
            height={150}
            width={150}
          />
          <p className="text-white">Please Wait ...</p>
        </div>
      ) : noAdsAvailable ? (
        <div className="flex flex-col items-center justify-center text-white">
          <h2 className="text-2xl">No ads available at the moment.</h2>
          <p>Please check back later for more ads.</p>
        </div>
      ) : (
        <>
          <div className="w-full max-w-3xl h-full relative">
            <ReactPlayer
              url={adsArray.ad_url}
              controls={false}
              playing={playNow}
              onEnded={handleAdEnd}
              width="100%"
              height="100%"
              style={{
                maxHeight: "100%",
                margin: "0 auto",
                objectFit: "cover"
              }}
              onReady={(player) => {
                const videoElement = player.getInternalPlayer();
                if (videoElement) {
                  videoElement.setAttribute("playsinline", "true");
                  videoElement.removeAttribute("controls");
                }
              }}
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                    disablePictureInPicture: true,
                    playsInline: true
                  }
                }
              }}
            />
            {adsArray.custom_link && (
              <div className="absolute top-0 m-5 flex items-end justify-center">
                <button
                  onClick={() =>
                    window.open(`${adsArray.custom_link}, "_blank"`)
                  }
                  className="py-2 px-4 mb-10 rounded-lg bg-[#F46D21] text-white transition-all"
                >
                  Visit site
                </button>
              </div>
            )}

            {!playNow && (
              <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                <button
                  onClick={() => setPlayNow(true)}
                  className="p-4 rounded-full bg-[#F46D21] gap-10 transition-all flex justify-center items-center duration-300"
                >
                  <FaPlay className="text-white text-l" />
                </button>
                {isPayed && (
                  <button
                    onClick={handleRefresh}
                    className="p-4 rounded-full ml-6 bg-[#F46D21] transition-all flex justify-center items-center duration-300"
                  >
                    <FaStepForward className="text-white text-l" />
                  </button>
                )}
              </div>
            )}
          </div>

          {!isPayed && isAdWatched && (
            <div className="absolute bottom-0 pb-9 z-50 w-full flex flex-col items-center justify-center space-y-4 bg-white">
              <h2 className="text-lg text-white">Claim Your Payment</h2>

              <div
                className={`flex${
                  isPaying ? "justify-center" : "gap-3"
                } w-full px-4 max-w-lg`}
              >
                {!isPaying && (
                  <div className="flex flex-col gap-10 mb-10">
                    <input
                      type="text"
                      name="paymentNumber"
                      placeholder="Enter your phone number"
                      value={paymentNumber}
                      onChange={handleInputChange}
                      className="p-4 border-2 border-[#F46D21] bg-transparent text-black font-bold text-s w-full rounded-xl text-lg outline-none"
                    />
                  </div>
                )}

                <div
                  onClick={!isPaying ? handleClaim : undefined}
                  className={`flex p-[15px] justify-center items-center rounded-2xl w-full 
                 ${
                   isPaying
                     ? "bg-[#F8A95C] cursor-not-allowed"
                     : "bg-[#F46D21] cursor-pointer"
                 }`}
                  disabled={isPaying}
                >
                  <h2 className="text-lg font-bold text-white">
                    {isPaying ? "Processing..." : "Claim"}
                  </h2>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AdPage;

